


























import Vue from 'vue'
import { mapState } from 'vuex'
import OTP from './components/OTP.vue'

export default Vue.extend({
  data(): {
    isEnable2FA: boolean
  } {
    return {
      isEnable2FA: false
    }
  },

  components: {
    OTP
  },

  computed: {
    ...mapState('auth', ['user'])
  },

  watch: {
    user: {
      handler() {
        this.isEnable2FA = this.user.security.otp
      },
      deep: true,
      immediate: true
    }
  }
})
